import axios from "axios";
import Paths from "../../constant/constant";

export async function addDeviceService(body) {
  return await axios
    .post(Paths.API_URL + `/api/v1/adevice/${body.refId}`, body)
    .then((res) => {
      return res.data;
    })
    .catch(function (e) {});
}
