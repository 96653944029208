import React from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  DropdownButton,
  Dropdown,
  Container,
} from "react-bootstrap";
import { deleteDevice } from "../../../services/DeviceServices/deleteDevice";
import "./BorrarDevice.css";

const BorrarDevice = ({ deviceToEdit, hideModal, modalShow, refreshData }) => {
  const deleteDeviceMethod = async () => {
    await deleteDevice(deviceToEdit.refId).then((res) => {
      refreshData();
      hideModal();
    });
  };
  return (
    <div>
      <Modal show={modalShow} onHide={() => hideModal()} size="xs" centered>
        <Modal.Header>
          <Modal.Title id="container-user-add">
            <h1>Dispositivo : {deviceToEdit.refId}</h1>
          </Modal.Title>
        </Modal.Header>
        <h1 className="borrar-device-popup-h1">
          Borrar el device con refId: {deviceToEdit.refId}
        </h1>
        <span className="borrar-device-popup-span">
          Esta seguro que desea borrar?
        </span>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => hideModal()}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => deleteDeviceMethod()}>
            Borrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default BorrarDevice;
