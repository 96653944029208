import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  DropdownButton,
  Dropdown,
  Container,
  Alert,
} from "react-bootstrap";
import { Switch } from "@mui/material";
import "./AddDevice.css";
import { addDeviceService } from "../../../services/DeviceServices/addDevice.js";
import { GetUsersActive } from "../../../services/Users/getUsers";
import Loader from "../../../components/Loader/Loader";
import SelectSearch from "react-select-search";
import fuzzySearch from "../../../components/Search/Search";

const AddDevice = ({ hideModal, modalShow, refreshData }) => {
  const [deviceToAdd, setDeviceToAdd] = useState({
    refId: "",
    descripcion: "-",
    estado: 0,
    idUsuarioAsoc: "",
    usuarioAsoc: "",
  });
  const [alertAdd, setAlertAdd] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropDownOfUsers, setDropDownOfUsers] = useState([
    { nombre: "Carlos Pelef", id: 1 },
    { nombre: "Pedro Feres", id: 2 },
    { nombre: "Json Mendez", id: 3 },
  ]);
  const [selectedItem, setSelectedItem] = useState(null);

  function handleUserAsoc(element) {
    setSelectedItem(element.id);
    setDeviceToAdd({
      ...deviceToAdd,
      idUsuarioAsoc: element.id,
      usuarioAsoc: element.nombre,
    });
  }

  const handleModificarDevice = (name, value) => {
    setDeviceToAdd({
      ...deviceToAdd,
      [name]: value,
    });
  };

  const getUsersDropDown = async () => {
    setLoading(true);

    await GetUsersActive()
      .then((res) => {
        setLoading(false);
        res.data.forEach((e) => {
          e.value = e.id;
          e.name = e.nombre;
        });
        setDropDownOfUsers(res.data);
      })
      .catch(function (e) {});
  };

  const addDeviceMethod = async (device) => {
    if (
      device.refId !== "" &&
      device.idUsuarioAsoc !== "" &&
      device.usuarioAsoc !== ""
    ) {
      setSelectedItem(null);
      await addDeviceService(device).then((res) => {
        refreshData();
        hideModal();
      });
    } else {
      setAlertAdd("Debe completar todos los campos correctamente");
    }
    setTimeout(() => {
      setAlertAdd("");
    }, 3500);
  };

  function hideAndSetNull() {
    setSelectedItem(null);
    hideModal();
  }

  useEffect(() => {
    getUsersDropDown();
  }, []);

  return (
    <div>
      <Modal show={modalShow} onHide={() => hideModal()} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="container-user-add">
            <h1>Agregar Device</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertAdd !== "" ? <Alert variant="danger">{alertAdd}</Alert> : null}

          <Form>
            <Container>
              <Row className="mb-3">
                <Form.Label>Ref ID unico</Form.Label>
                <Form.Control
                  type="text"
                  name="refID"
                  placeholder="refID/codigo de tarjeta"
                  onChange={(e) =>
                    handleModificarDevice("refId", e.target.value)
                  }
                />
              </Row>

              <Row className="mb-3">
                <Form.Label>Descripcion</Form.Label>
                <Form.Control
                  type="text"
                  name="Descripcion"
                  placeholder="Descripcion"
                  onChange={(e) =>
                    handleModificarDevice("descripcion", e.target.value)
                  }
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Estado</Form.Label>
                <Switch
                  defaultChecked={deviceToAdd?.estado === 0 ? false : true}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(e) =>
                    handleModificarDevice(
                      "estado",
                      e.target.checked === true ? 1 : 0
                    )
                  }
                />
              </Row>

              <Row className="mb-3">
                <Form.Label>Persona Asociada</Form.Label>

                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    <SelectSearch
                      options={dropDownOfUsers}
                      search
                      placeholder="Seleccione una Persona"
                      filterOptions={fuzzySearch}
                      value={selectedItem}
                      maxMenuHeight={250}
                      menuPlacement="auto"
                      onChange={(id, item) => handleUserAsoc(item)}
                    />
                  </div>
                )}
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => hideAndSetNull()}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={() => addDeviceMethod(deviceToAdd)}
          >
            Agregar +
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddDevice;
