import React, { useEffect, useState } from "react";
import "./Home.css";
import { Bar } from "react-chartjs-2";
import { GetUsers } from "../../services/Users/getUsers";
import getDevices from "../../services/DeviceServices/getDevices";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import Mei from "./Mei/Mei";
const Home = () => {
  const [countDevices, setCountDevices] = useState({
    devicesOn: 1,
    devicesOff: 1,
  });
  const [countUsers, setCountUsers] = useState({
    usersOff: 1,
    usersOn: 1,
  });

  const getDatas = () => {
    Promise.all([GetUsers(), getDevices()]).then((res) => {
      let devicesOn = res[1].data;
      let devicesOff = res[1].data;
      devicesOn = devicesOn.filter((e) => e.estado === 1).length;
      devicesOff = devicesOff.filter((e) => e.estado === 0).length;

      let usersOff = res[0].data;
      let usersOn = res[0].data;
      usersOff = usersOff.filter((e) => e.activo === false).length;
      usersOn = usersOn.filter((e) => e.activo === true).length;
      setCountUsers({ usersOff: usersOff, usersOn, usersOn });
      setCountDevices({ devicesOn: devicesOn, devicesOff: devicesOff });
    });
  };

  useEffect(() => {
    getDatas();
  }, []);

  const data = {
    labels: [
      "Usuarios Activos",
      "Usuarios Inactivos",
      "Tarjetas Activas",
      "Tarjetas Inactivas",
    ],
    datasets: [
      {
        label: "Activos",
        data: [
          countUsers.usersOn,
          countUsers.usersOff,
          countDevices.devicesOn,
          countDevices.devicesOff,
        ],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "SMT Control",
      },
    },
  };
  return (
    <div>
      <h1>
        {" "}
        H<FingerprintIcon fontSize="large" />
        ME
      </h1>
      <Mei />

      <Bar data={data} options={options} />
    </div>
  );
};
export default Home;
