import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { db, auth } from "./firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Button } from "react-bootstrap";

//ROUTES
import Login from "./routes/Login/Login";
import Home from "./routes/Home/Home.jsx";
import Devices from "./routes/Devices/Devices.jsx";
import Dashboard from "./routes/DashBoard/Dashboard.jsx";
import Users from "./routes/Users/Users.jsx";
import Page404 from "./routes/RedirectPage/page404.jsx";

function App() {
  const [userAuth, setUserAuth] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [usuarioData, setUsuarioData] = useState({
    name: "",
    email: "",
    permits: "",
    permitsEnum: 0,
  });
  // ========== FIREBASE AUTH ============

  async function buscarEnFireStore(user) {
    const collectRef = collection(db, "clientes");
    const docRef = doc(db, "clientes", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUsuarioData(docSnap.data());
      setIsLoading(true);
      setIsDataLoading(true);
    } else {
    }
  }
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserAuth(true);
        buscarEnFireStore(user);
      } else {
        setUserAuth(false);
        // No user is signed in.
      }
    });
    if (!userAuth) {
      setIsLoading(true);
    }
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Router>
          <Route exact path="/" exact>
            <Login data={{ usuarioData, userAuth }} />
          </Route>

          {isDataLoading ? (
            <div className="App">
              <Switch>
                <Route exact path="/Devices" exact>
                  <Dashboard data={{ usuarioData, userAuth }}>
                    <Devices data={{ usuarioData, userAuth }} />
                  </Dashboard>
                </Route>

                <Route exact path="/Home" exact>
                  <Dashboard data={{ usuarioData, userAuth }}>
                    <Home data={{ usuarioData, userAuth }} />
                  </Dashboard>
                </Route>

                <Route exact path="/Users" exact>
                  <Dashboard data={{ usuarioData, userAuth }}>
                    <Users data={{ usuarioData, userAuth }} />
                  </Dashboard>
                </Route>
              </Switch>
            </div>
          ) : null}
        </Router>
      ) : (
        <Router>
          <Route path="*">
            <Page404 />
          </Route>
          <Route path="/404">
            <Page404 />
          </Route>
        </Router>
      )}
    </div>
  );
}

export default App;
