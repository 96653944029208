import {initializeApp} from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
//FIN DE IMPORTS

//CONFIGURACIÓN FIREBASE
const config =  {
  apiKey: "AIzaSyCoWHMZr9lw-RK5a0UpP1zrs9VIGJSs0e8",
  authDomain: "smt-control-access.firebaseapp.com",
  projectId: "smt-control-access",
  storageBucket: "smt-control-access.appspot.com",
  messagingSenderId: "171110546818",
  appId: "1:171110546818:web:dc14ce87f16edbad08239b"
};

//INICIALIZACIÓN DE LA APP
const app = initializeApp(config);

//DB
export const db = getFirestore();

//AUTENTICACIÓN
export const auth = getAuth();
// avoid deprecated warnings
// db.settings({
//   timestampsInSnapshots: true,
// });
