import React, { useState, useEffect } from "react";
import "./EditDevice.css";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  DropdownButton,
  Dropdown,
  Container,
} from "react-bootstrap";
import { Switch } from "@mui/material";
import { putDevice } from "../../../services/DeviceServices/putDevice.js";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { GetUsersActive } from "../../../services/Users/getUsers";
import Loader from "../../../components/Loader/Loader";
import SelectSearch from "react-select-search";
import fuzzySearch from "../../../components/Search/Search";

const EditDevice = ({ deviceToEdit, hideModal, modalShow, refreshData }) => {
  const [thisDeviceToEdit, setThisDeviceToEdit] = useState(deviceToEdit);
  const [dropDownOfUsers, setDropDownOfUsers] = useState([
    { nombre: "Carlos Pelef", id: 1 },
    { nombre: "Pedro Feres", id: 2 },
    { nombre: "Json Mendez", id: 3 },
  ]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(deviceToEdit.idUsuarioAsoc);

  function handleChange(e, value) {
    handleModificarDevice("estado", value === true ? 1 : 0);
  }

  const putDeviceMethod = async (device) => {
    await putDevice(device).then((res) => {
      refreshData();
      hideModal();
    });
  };
  const getUsersDropDown = async () => {
    setLoading(true);

    await GetUsersActive()
      .then((res) => {
        setLoading(false);
        res.data.forEach((e) => {
          e.value = e.id;
          e.name = e.nombre;
        });
        setDropDownOfUsers(res.data);
      })
      .catch(function (e) {});
  };

  const handleModificarDevice = (name, value) => {
    setThisDeviceToEdit({
      ...thisDeviceToEdit,
      [name]: value,
    });
  };

  function handleDeviceAsoc(element) {
    setSelectedItem(element.id);
    setThisDeviceToEdit({
      ...thisDeviceToEdit,
      idUsuarioAsoc: element.id,
      usuarioAsoc: element.nombre,
    });
  }

  useEffect(() => {
    setSelectedItem(deviceToEdit.idUsuarioAsoc);
    setThisDeviceToEdit(deviceToEdit);
    getUsersDropDown();
  }, [deviceToEdit]);

  return (
    <Modal show={modalShow} onHide={() => hideModal()} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="container-device-edit">
          <div className="device-edit-header-modal">
            <h1>Editar device {deviceToEdit.refId}</h1>
            <FingerprintIcon />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="devices-edit-form">
          <Container>
            <Row className="mb-3">
              <Col>
                <Form.Label>Descripcion:</Form.Label>
                <Form.Control
                  type="text"
                  name="Descripcion"
                  defaultValue={deviceToEdit.descripcion}
                  onChange={(e) =>
                    handleModificarDevice("descripcion", e.target.value)
                  }
                />
              </Col>
              <Col>
                <Row>
                  <Form.Label>Estado:</Form.Label>
                </Row>
                <Row>
                  <Switch
                    defaultChecked={deviceToEdit.estado === 1}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) =>
                      handleModificarDevice(
                        "estado",
                        e.target.checked === true ? 1 : 0
                      )
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Persona:</Form.Label>
                {loading ? (
                  <Loader sincss={true} alto={100} />
                ) : (
                  <>
                    {/* <DropdownButton
                      id="dropdown-basic-button"
                      title={thisDeviceToEdit?.usuarioAsoc}
                      variant="secondary"
                    >
                      {dropDownOfUsers.map((element) => {
                        return (
                          <Dropdown.Item
                            id={element.id}
                            onClick={() => {
                              handleDeviceAsoc(element);
                            }}
                          >
                            {element.nombre}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton> */}
                    <SelectSearch
                      options={dropDownOfUsers}
                      search
                      placeholder="Seleccione una Persona"
                      filterOptions={fuzzySearch}
                      value={selectedItem}
                      onChange={(id, item) => handleDeviceAsoc(item)}
                    />
                  </>
                )}
              </Col>
              <Col>
                <Row>
                  <Form.Label>RefID:</Form.Label>
                </Row>
                <FingerprintIcon />
                {deviceToEdit?.refId}
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => hideModal()}>Cerrar</Button>
        <Button
          variant="success"
          onClick={() => putDeviceMethod(thisDeviceToEdit)}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditDevice;
