import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  DropdownButton,
  Dropdown,
  Container,
  Alert,
} from "react-bootstrap";
import { Switch } from "@mui/material";
import { addUser } from "../../../services/Users/addUser.js";

const AddUser = ({ hideModal, modalShow, refreshData }) => {
  const [userToAdd, setUserToAdd] = useState({
    nombre: "",
    telefono: "",
    direccion: "",
    email: "",
    activo: true,
  });
  const [alertAdd, setAlertAdd] = useState("");

  const handleModificarUser = (name, value) => {
    setUserToAdd({
      ...userToAdd,
      [name]: value,
    });
  };

  const addUserMethod = async (user) => {
    if (
      user.nombre !== "" &&
      user.telefono !== "" &&
      user.direccion !== "" &&
      user.email !== ""
    ) {
      await addUser(user).then((res) => {
        refreshData();
        hideModal();
      });
    } else {
      setAlertAdd("Debe completar todos los campos correctamente");
    }
    setTimeout(() => {
      setAlertAdd("");
    }, 3500);
  };

  return (
    <div>
      <Modal show={modalShow} onHide={() => hideModal()} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="container-user-add">
            <h1>Agregar Persona</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertAdd !== "" ? <Alert variant="danger">{alertAdd}</Alert> : null}
          <Form>
            <Container>
              <Row className="mb-3">
                <Form.Label>Nombre Completo</Form.Label>
                <Form.Control
                  type="text"
                  name="Nombre"
                  placeholder="Nombre"
                  onChange={(e) =>
                    handleModificarUser("nombre", e.target.value)
                  }
                />
              </Row>

              <Row className="mb-3">
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  type="number"
                  name="Tel"
                  placeholder="Tel"
                  onChange={(e) =>
                    handleModificarUser("telefono", e.target.value)
                  }
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Direccion</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dir"
                  name="Direccion"
                  onChange={(e) =>
                    handleModificarUser("direccion", e.target.value)
                  }
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="E-mail"
                  name="Email"
                  onChange={(e) => handleModificarUser("email", e.target.value)}
                />
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => hideModal()}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => addUserMethod(userToAdd)}>
            Agregar +
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AddUser;
