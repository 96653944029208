import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";
import AlertError from "../../components/Alerta/AlertError";
// import Paths from "../../constant/constant";
import { Redirect } from "react-router-dom";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import PanToolIcon from "@mui/icons-material/PanTool";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SmartLedge from "../../img/SmartLedge.jpg";
const Login = ({ data }) => {
  // => Valores iniciales login
  const initialLoginValues = {
    email: "",
    password: "",
  };

  // ESTADOS
  // => Estado valores login
  const [loginValues, setLoginValues] = useState(initialLoginValues);
  const [estadoBotones, setEstadoBotones] = useState(false);
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState(null);

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     setRedirect(`/Home`);
  //   } else {
  //     // No user is signed in.
  //     // setRedirect(`/`);
  //   }
  // });

  // => Capturar evento onChange
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLoginValues({ ...loginValues, [name]: value });
  };

  // => Submit formulario
  const handleSubmitForm = (e) => {
    e.preventDefault();
    setEstadoBotones(true);
    signInWithEmailAndPassword(auth, loginValues.email, loginValues.password)
      .then((user) => {
        setRedirect(`/Home`);
        // db.collection("clientes")
        //   .where("email", "==", user.user.email)
        //   .get()
        //   .then((snapshot) => {
        //     snapshot.forEach((doc) => {
        //       switch (doc.data().tipo) {
        //         case "Entrenador":
        //           setRedirect(`/Profesor/${user.user.uid}`);
        //           break;
        //         case "Suscriptor":
        //           setRedirect(`/Usuario/${user.user.uid}`);
        //           break;
        //         case "Administrador":
        //           setRedirect(`/Administracion/${user.user.uid}`);
        //           break;
        //         default:
        //           setErrorMsg(
        //             "Ha ocurrido un error con el usuario, verifique sus datos e intente nuevamente."
        //           );
        //       }
        //     });
        //   });
      })
      .catch((error) => {
        // setError("Error", error.code);
        setearCaseError(error.code);
        setError("");
        setEstadoBotones(false);
      });
  };

  const setearCaseError = (e) => {
    switch (e) {
      case "auth/wrong-password":
        setError("Contraseña Incorrecta");
        break;
      case "auth/user-not-found":
        setError("Email no encontrado u erroneo");
        break;
      case "auth/invalid-email":
        setError("Email no encontrado u erroneo");
        break;
      default:
        break;
    }
  };
  const ErrorData = {
    icon: "error",
    title: "Oops..",
    type: "error",
    text: error.toString(),
    footer: "<a href>Why do I have this issue?</a>",
  };

  useEffect(() => {
    if (data.userAuth) {
      setRedirect(`/Home`);
    }
  }, [data.userAuth]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="login-container">
      <h1 className="mt-5 login-header-title">
        <span className="login-header-title-second-color">
          C<FingerprintIcon fontSize="large" />
          ntr
          <FingerprintIcon fontSize="large" />l de{" "}
        </span>
        Acces
        <FingerprintIcon fontSize="large" />
      </h1>

      {error !== "" ? <AlertError {...ErrorData} /> : <div></div>}
      <div className="login-form-container">
        <Form
          onSubmit={(e) => handleSubmitForm(e)}
          className="login-form-container-form"
        >
          {/* <h2 className="login-form-container-label mb-2">Bienvenido</h2> */}
          {/* <PanToolIcon fontSize="large" /> */}
          <img src={SmartLedge} alt="" className="login-form-img" />
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="login__label">Correo Electrónico</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="email"
              name="email"
              placeholder="Ingrese su correo"
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="login__label">Contraseña</Form.Label>
            <Form.Control
              onChange={handleInputChange}
              type="password"
              name="password"
              placeholder="Contraseña"
            />
          </Form.Group>
          <Button
            className="login__button"
            variant="outline-primary"
            type="submit"
            disabled={estadoBotones}
          >
            Acceder
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default Login;
