import React, { useState, useEffect } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DataTable, { createTheme } from "react-data-table-component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "react-bootstrap/Button";
import "./Users.css";
import EditUser from "./EditUser/EditUser";
import AddUser from "./Adduser/AddUser";
import Avatar from "react-avatar";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AttributionIcon from "@mui/icons-material/Attribution";
import { GetUsers } from "../../services/Users/getUsers";
import Loader from "../../components/Loader/Loader";
import TextField from "@mui/material/TextField";

const Users = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalAddShow, setModalAddShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const [data, setData] = useState([
    {
      id: 1,
      nombre: "Juan carlos",
      telefono: "444022033",
      direccion: "A 3 casa 20",
      email: "juancarlos@gmail.com",
      activo: false,
    },
    {
      id: 2,
      nombre: "Beetl Dario",
      telefono: "266555555",
      direccion: "A 3 casa 90",
      email: "beet@gmail.com",
      activo: true,
    },
  ]);

  function openModalEdit(row) {
    setModalShow(true);
    setUserToEdit(row);
  }
  function openModalAdd(row) {
    setModalAddShow(true);
  }

  const getUserMethod = async () => {
    setLoading(true);
    await GetUsers()
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setFilteredItems(res.data);
      })
      .catch(function (e) {});
  };

  const columns = [
    {
      name: "Avatar",

      selector: (row) => <Avatar name={row.nombre} size="30" round={true} />,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Telefono",
      selector: (row) => row.telefono,
      sortable: true,
    },
    {
      name: "Direccion",
      selector: (row) => row.direccion,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Activo",
      selector: (row) =>
        row.activo ? (
          <AttributionIcon color="success" />
        ) : (
          <AttributionIcon color="error" />
        ),
      sortable: true,
    },
    {
      selector: (row) => (
        <Button variant="outline-primary" onClick={() => openModalEdit(row)}>
          <ModeEditIcon />
        </Button>
      ),
      sortable: true,
    },
  ];

  function hideModal() {
    setModalShow(false);
  }

  function hideAddModal() {
    setModalAddShow(false);
  }

  function findItem(e) {
    if (e !== "") {
      setFilterText(e);
      setFilteredItems(
        data.filter((item) =>
          item.nombre.toLowerCase().includes(e.toLowerCase())
        )
      );
    } else {
      setFilterText(e);
      setFilteredItems(data);
    }
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextField
          id="search"
          type="text"
          variant="standard"
          label="Buscar"
          value={filterText}
          onChange={(e) => findItem(e.target.value)}
          placeholder="Nombre"
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getUserMethod();
  }, []);

  return (
    <div>
      <SupervisedUserCircleIcon />
      <h1>Personas</h1>

      <Button
        className="users-button-add"
        variant="success"
        onClick={() => openModalAdd()}
      >
        <AddCircleOutlineIcon />
      </Button>
      <EditUser
        userToEdit={userToEdit}
        hideModal={hideModal}
        modalShow={modalShow}
        refreshData={getUserMethod}
      />
      <AddUser
        refreshData={getUserMethod}
        hideModal={hideAddModal}
        modalShow={modalAddShow}
      />

      {loading ? (
        <Loader />
      ) : (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          paginationResetDefaultPage={resetPaginationToggle}
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          noDataComponent="No se encontraron datos"
        />
      )}
    </div>
  );
};
export default Users;
