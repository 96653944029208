import React, { useState, useEffect } from "react";
import "./Devices.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DataTable, { createTheme } from "react-data-table-component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  DropdownButton,
  Dropdown,
  Container,
} from "react-bootstrap";
import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import CreditCardOffRoundedIcon from "@mui/icons-material/CreditCardOffRounded";
import { Switch } from "@mui/material";
import EditDevice from "./EditDevice/EditDevice";
import AddDevice from "./AddDevice/AddDevice";
import BorrarDevice from "./BorrarDevice/BorrarDevice";
import TextField from "@mui/material/TextField";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import Loader from "../../components/Loader/Loader";
import getDevices from "../../services/DeviceServices/getDevices";
import addMasive from "../../services/scriptAdd";

import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";

const Devices = () => {
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [modalAddShow, setModalAddShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceToEdit, setDeviceToEdit] = useState([]);
  const [deviceToDelete, setDeviceToDelete] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [data, setData] = useState([
    {
      id: 1,
      refId: "12312312d",
      descripcion: "Descripcion de tarjeta 12312312d",
      estado: 1,
      usuarioAsoc: "Rocca",
      idUsuarioAsoc: 1,
    },
    {
      id: 2,
      refId: "123122aweqe",
      descripcion: "Descripcion de tarjeta 123122aweqe",
      estado: 0,
      usuarioAsoc: "Gonzalo Alvarez",
      idUsuarioAsoc: 2,
    },
  ]);

  function deleteHideModal() {
    setDeleteModalShow(false);
  }
  function hideModal() {
    setModalShow(false);
  }
  function hideAddModal() {
    setModalAddShow(false);
  }

  const getDevicesMethod = async () => {
    setLoading(true);
    await getDevices()
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setFilteredItems(res.data);
      })
      .catch(function (e) {});
  };
  const columns = [
    {
      name: "RefID",
      selector: (row) => row.refId,
      sortable: true,
    },

    {
      name: "Usuario Asignado",
      selector: (row) => row.usuarioAsoc,
      sortable: true,
    },
    {
      name: "Descripcion",
      selector: (row) => row.descripcion,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => {
        return row.estado === 1 ? (
          <CreditCardRoundedIcon color="success" />
        ) : (
          <CreditCardOffRoundedIcon color="error" />
        );
      },
      sortable: true,
    },
    {
      name: "Editar",
      selector: (row) => (
        <Button variant="outline-primary" onClick={() => openModalEdit(row)}>
          <ModeEditIcon />
        </Button>
      ),
      sortable: true,
    },

    {
      name: "Borrar",
      selector: (row) => (
        <Button variant="outline-danger" onClick={() => openModalDelete(row)}>
          <DeleteIcon />
        </Button>
      ),
      sortable: true,
    },
  ];

  function openModalEdit(row) {
    setModalShow(true);
    setDeviceToEdit(row);
  }
  function openModalAdd(row) {
    setModalAddShow(true);
  }
  function openModalDelete(row) {
    setDeleteModalShow(true);
    setDeviceToDelete(row);
  }

  function handleChange(e) {}

  function findItem(e) {
    if (e !== "") {
      setFilterText(e);
      setFilteredItems(
        data.filter((item) =>
          item.usuarioAsoc.toLowerCase().includes(e.toLowerCase())
        )
      );
    } else {
      setFilterText(e);
      setFilteredItems(data);
    }
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <TextField
          id="search"
          type="text"
          variant="standard"
          label="Buscar"
          value={filterText}
          onChange={(e) => findItem(e.target.value)}
          placeholder="Nombre"
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getDevicesMethod();
  }, []);

  async function addMasiveMethod() {
    await addMasive()
      .then((res) => {
        console.log(res);
      })
      .catch(function (e) {});
    {
      /* <Button
        className="devices-button-add-device"
        variant="outline-success"
        onClick={() => addMasiveMethod()}
      >
        cargarmasive
      </Button> */
    }
  }

  return (
    <div>
      <FingerprintIcon />
      <h1>| Tarjetas | </h1>

      <Button
        className="devices-button-add-device"
        variant="outline-success"
        onClick={() => openModalAdd()}
      >
        <AddCircleOutlineIcon />
      </Button>

      <EditDevice
        refreshData={getDevicesMethod}
        deviceToEdit={deviceToEdit}
        hideModal={hideModal}
        modalShow={modalShow}
      />
      <AddDevice
        refreshData={getDevicesMethod}
        hideModal={hideAddModal}
        modalShow={modalAddShow}
      />
      <BorrarDevice
        refreshData={getDevicesMethod}
        hideModal={deleteHideModal}
        modalShow={deleteModalShow}
        deviceToEdit={deviceToDelete}
      />

      {loading ? (
        <Loader />
      ) : (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          paginationResetDefaultPage={resetPaginationToggle}
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          noDataComponent="No se encontraron datos"
        />
      )}
    </div>
  );
};

export default Devices;
