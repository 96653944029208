import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import LayersIcon from "@mui/icons-material/Layers";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, Redirect } from "react-router-dom";
import "./listItems.css";
import { signOut } from "firebase/auth";
import { db, auth } from "../../../firebase";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Paths from "../../../constant/constant";

export const mainListItems = (
  <div className="list-items-link-text">
    <Link to="/Home">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>

    <Link to="/Users">
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Personas" />
      </ListItem>
    </Link>
    <Link to="/Devices">
      <ListItem button>
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>

        <ListItemText primary="Tarjetas" />
      </ListItem>
    </Link>
  </div>
);

// ==== CERRAR SESIÓN ====
let cerrarSesion = () => {
  signOut(auth)
    .then(() => {
      window.location.href = Paths.SITE_URL;
      // return <Redirect to="/" />;
    })
    .catch((error) => {
      // An error happened.
    });
};

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Mas opciones</ListSubheader>
    <ListItem button onClick={() => cerrarSesion()}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Salir" />
    </ListItem>
  </div>
);
