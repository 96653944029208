import axios from "axios";
import Paths from "../../constant/constant";

export default async function getDevices() {
  return await axios
    .get(Paths.API_URL + "/api/v1/getdevices")
    .then((res) => {
      return res.data;
    })
    .catch(function (er) {});
}
