import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Modal,
  DropdownButton,
  Dropdown,
  Container,
} from "react-bootstrap";
import { Switch } from "@mui/material";
import { putUser } from "../../../services/Users/putUser.js";

const EditUser = ({ userToEdit, hideModal, modalShow, refreshData }) => {
  const [thisUserToEdit, setThisUserToEdit] = useState(userToEdit);

  const handleModificarUser = (name, value) => {
    setThisUserToEdit({
      ...thisUserToEdit,
      [name]: value,
    });
  };

  const putUserMethod = async (user) => {
    await putUser(user).then((res) => {
      refreshData();
      hideModal();
    });
  };

  useEffect(() => {
    setThisUserToEdit(userToEdit);
  }, [userToEdit]);

  return (
    <div>
      <Modal show={modalShow} onHide={() => hideModal()} size="lg" centered>
        <Modal.Header>
          <Modal.Title id="container-edit-user">
            <h1>Editar {userToEdit.nombre}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-3">
                <Form.Label>Nombre Completo</Form.Label>
                <Form.Control
                  type="text"
                  name="Descripcion"
                  defaultValue={userToEdit.nombre}
                  onChange={(e) =>
                    handleModificarUser("nombre", e.target.value)
                  }
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  type="number"
                  name="Descripcion"
                  defaultValue={userToEdit.telefono}
                  onChange={(e) =>
                    handleModificarUser("telefono", e.target.value)
                  }
                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Direccion</Form.Label>
                <Form.Control
                  type="text"
                  name="Descripcion"
                  defaultValue={userToEdit.direccion}
                  onChange={(e) =>
                    handleModificarUser("direccion", e.target.value)
                  }
                />
              </Row>
              <Row>
                <Row>
                  <Form.Label>Activo:</Form.Label>
                </Row>
                <Row>
                  <Switch
                    defaultChecked={userToEdit.activo}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) =>
                      handleModificarUser(
                        "activo",
                        e.target.checked === true ? true : false
                      )
                    }
                  />
                </Row>
              </Row>
              {/* <Row className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="Descripcion"
                  defaultValue={userToEdit.email}
                  onChange={(e) => handleModificarUser("email", e.target.value)}
                />
              </Row> */}
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => hideModal()}>Cerrar</Button>
          <Button
            variant="success"
            onClick={() => putUserMethod(thisUserToEdit)}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default EditUser;
