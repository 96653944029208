import { TableBody } from "@mui/material";
import axios from "axios";
import Paths from "../../constant/constant";

export async function GetMei() {
  return await axios
    .get(Paths.API_URL + "/api/v1/getmeis")
    .then((res) => {
      return res.data;
    })
    .catch(function (e) {});
}

export async function GetDataMei(param, desde, hasta) {
  if (desde === null || hasta === null) {
    var body = {
      id: param,
      max: 50,
      desde: null,
      hasta: null,
    };
  } else {
    var body = {
      id: param,
      max: 50,
      desde: desde,
      hasta: hasta,
    };
  }

  return await axios
    .post(Paths.API_URL + "/api/v1/logs", body)
    .then((res) => {
      return res;
    })
    .catch(function (e) {});
}
