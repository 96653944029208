import axios from "axios";
import Paths from "../../constant/constant";

export async function putUser(body) {
  return await axios
    .post(Paths.API_URL + `/api/v1/update/${body.email}`, body)
    .then((res) => {
      // console.log(res);
    })
    .catch(function (e) {});
}
