import React, { useEffect, useState } from "react";
import "./Mei.css";
import { GetMei, GetDataMei } from "../../../services/Mei/getMei";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../../components/Loader/Loader";
import Table from "react-bootstrap/Table";
import jsPDF from "jspdf";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ContactlessIcon from "@mui/icons-material/Contactless";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SensorsIcon from "@mui/icons-material/Sensors";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";
// import setMinutes from "date-fns/setMinutes";
// import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";

const Mei = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 600000 * 1000)
  );
  const [startEnd, setEndDate] = useState(new Date());

  const [ultimoMeiId, setUltimoMeiId] = useState(null);
  const [desde, setDesde] = useState(
    new Date(new Date().getTime() - 600000 * 1000)
  );
  const [hasta, setHasta] = useState(new Date());
  const [meiData, setMeiData] = useState([]);
  const [show, setShow] = useState(false);

  const [isDataUp, setIsDataUp] = useState(false);
  const [logMei, setLogMei] = useState([]);

  const handleClose = () => {
    setIsDataUp(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getDatas = async () => {
    await GetMei()
      .then((res) => {
        setMeiData(res);
      })
      .catch(function (e) {});
  };

  async function downloadData(param) {
    handleShow();
    setUltimoMeiId(param);
    await GetDataMei(param, null, null)
      .then((res) => {
        setIsDataUp(true);
        setLogMei(res.data);
      })
      .catch(function (e) {});
  }

  function generatePdf() {
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#ContentLog"), {
      callback: function (pdf) {
        var pageCount = doc.internal.getNumberOfPages();
        pdf.deletePage(pageCount);
        pdf.save(`DeviceLog${new Date().toLocaleDateString()}.pdf`);
      },
    });
  }

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  function setearDate(date, from) {
    if (from === "desde") {
      setStartDate(date);
      console.log("from desde", date);
      setDesde(date);
    } else {
      setEndDate(date);
      console.log("from hasta", date);

      setHasta(date);
    }
  }
  async function buscarPorFecha() {
    if (ultimoMeiId !== null) {
      setIsDataUp(false);

      await GetDataMei(ultimoMeiId, desde, hasta)
        .then((res) => {
          setIsDataUp(true);
          setLogMei(res.data);
        })
        .catch(function (e) {});
    }
  }

  useEffect(() => {
    getDatas();
  }, []);
  return (
    <div className="mei-contenedor-padre">
      <div className="contenedor-mei-cards">
        {meiData?.map((item) => {
          return (
            <div
              className={`card-contenedor-padre ${
                item.status === "online" ? "online" : "offline"
              }`}
            >
              <div className="card-header-mei">
                <PowerSettingsNewIcon
                  color={item.status === "online" ? "success" : "error"}
                />
                <h1>{item.label != "" ? item.label : "Sin nombre"}</h1>
              </div>

              <div className="card-body-status">
                <div>
                  <h1>{item.status}</h1>
                </div>
                <div>
                  <SensorsIcon
                    className="icon-loading"
                    fontSize="small"
                    color={item.status === "online" ? "success" : "error"}
                  />
                </div>
              </div>

              <div className="card-item-body">
                <div className="card-item-body-midle">
                  {item.label === "Entrada" ? (
                    <LoginIcon color="warning" />
                  ) : (
                    <LogoutIcon color="warning" />
                  )}

                  <h1
                    className={
                      item.label === "Entrada"
                        ? "letter-online"
                        : "letter-offline"
                    }
                  >
                    {item.label}
                  </h1>
                </div>
                <div className="card-footer-download">
                  <CloudDownloadIcon
                    onClick={() => downloadData(item.device_id)}
                    fontSize="small"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        className="modal-css"
        size="lg"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <Modal.Title>Vista previa</Modal.Title>
          <div className="contenedor-buscador-por-fechas">
            <div className="mei-contenedor-padre-datepicker">
              <h1>Desde:</h1>
              <DatePicker
                selected={startDate}
                onChange={(date) => setearDate(date, "desde")}
                dateFormat="dd/MM/yyyy"
                className="input-picker"
              />
            </div>
            <div className="mei-contenedor-padre-datepicker">
              <h1>Hasta:</h1>
              <DatePicker
                selected={startEnd}
                onChange={(date) => setearDate(date, "hasta")}
                dateFormat="dd/MM/yyyy"
                className="input-picker"
              />
            </div>

            <Button
              variant="primary"
              size="sm"
              onClick={() => buscarPorFecha()}
            >
              <SearchIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isDataUp ? (
            <div className="pdf-contenedor">
              <div id="ContentLog">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Device ID: </th>
                      <th>User ID</th>
                      <th>User Desc</th>
                      <th>Resultado</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logMei?.map((item) => {
                      return (
                        <tr>
                          <td>{item.device_id}</td>
                          <td>{item.payload.user_id}</td>
                          <td>{item.payload.user_device_id}</td>
                          <td>
                            <div className="resultado-mei">
                              {item.payload.result}
                              {/* {item.payload.result === "granted" ? (
                                <GppGoodIcon color="success" />
                              ) : (
                                <RemoveCircleOutlineIcon color="error" />
                              )} */}
                            </div>
                          </td>
                          <td>{timeConverter(item.timestamp)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            <div className="loader-center-mei">
              <Loader sincss={true} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => generatePdf()}>
            Descargar <CloudDownloadIcon />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Mei;
